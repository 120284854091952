<template>
  <div class="col main_videos_wrapper">
    <!-- <card>
      <div
        class="hide_for_share_pdf"
        :class="{ main_youtube_html_render: isRtl() }"
        v-if="profileInstructions"
        v-html="profileInstructions"
      ></div>
    </card>
    <div>{{ videos[0].body == null ? "yes" : "no" }}</div> -->
    <div class=" mb-2" v-if="videos[0]" v-html="videos[0].body"></div>
    <card>
      <div
        class="youtube_html_container_cms mb-4"
        :class="{ main_youtube_html_render_for_tasks: isRtl() }"
        v-if="videos[1]"
        v-html="videos[1].body"
      ></div>
    </card>
  </div>
</template>

<script>
export default {
  computed: {
    profileInstructions() {
      return this.$store.getters["user/taskInstructions"]
        ? this.$store.getters["user/taskInstructions"][1].body
        : "";
    },
    taskInstructions() {
      return this.$store.getters["user/taskInstructions"]
        ? this.$store.getters["user/taskInstructions"][0].body
        : "";
    },
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    videos() {
      return this.$store.getters["user/get_videos"];
    }
  },
  mounted() {
    this.$store.dispatch("user/user", this.auth().id);
    this.Refresh();
  },
  methods: {
    Refresh(query) {
      this.$store
        .dispatch("user/userTask", { id: this.id, query: query })
        .then(() => {
          this.$store.dispatch("topics/GetTopics", { query: null });
        });
      this.$store.dispatch("user/handlerGetVideos");
      //
    }
  }
};
</script>

<style lang="scss" scoped>
.main_videos_wrapper {
  .main_youtube_html_render {
    // background: #11bfe3;
    .text-muted {
      text-align: right;
    }
    .card-category {
      text-align: right;
      list-style: none;
      li {
        text-align: right;
        position: relative;
        padding-right: 17px;
        text-align: right;
        &::before {
          position: absolute;
          content: "";
          background-color: #706f6f;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          right: 0;
          top: 7px;
        }
      }
    }
  }

  .personalize_title {
    &.is_rtl {
      display: flex;
      flex-direction: row-reverse;
      gap: 8px;
    }
  }
  .main_youtube_html_render_for_tasks {
    .row {
      flex-direction: row-reverse;
      ul {
        text-align: right;
        list-style: none;
        margin-left: 0 !important;
        padding-left: 0 !important;
        li {
          text-align: right;
          position: relative;
          padding-right: 17px;
          text-align: right;
          &::before {
            position: absolute;
            content: "";
            background-color: #706f6f;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            right: 0;
            top: 7px;
          }
        }
      }
    }
  }
  // youtube_html_container_cms Style
  .youtube_html_container_cms {
    min-height: 400px;
  }
}
</style>
